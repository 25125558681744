import React from 'react'
import Layout from 'components/layout'
import ScrollableContent from 'components/scrollable-content'
import ProductGrid from 'components/product-grid'
import Spacer from 'components/spacer'
import useCommercialMowers from 'data/use-commercial-mowers'
import { Link } from 'gatsby'
import Button from 'components/button'

const CommercialMowersPage = () => {
  const commercialMowersData = useCommercialMowers()
  return (
    <Layout
      seo={{
        title: 'Commercial Mowers',
        description:
          'Build and price a John Deere commercial mower to meet your needs. Customize your build with options, attachments, warranty, and financing and easily calculate the costs.',
      }}
    >
      <ScrollableContent>
        <h2>Commercial Mowers</h2>
        <Spacer size='m' />
        {commercialMowersData.length > 0 ? (
          <ProductGrid items={commercialMowersData} />
        ) : (
          <React.Fragment>
            <p>No commercial mowers are available to build and price at this time.</p>
            <Button
              as={Link}
              to='/'
              ghost
              style={{ display: 'inline-block', textDecoration: 'none' }}
            >
              Go to homepage
            </Button>
          </React.Fragment>
        )}
      </ScrollableContent>
    </Layout>
  )
}

export default CommercialMowersPage
